@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';
@mixin ag-grid-mixin($theme) {
  $neutralColor: mat.get-theme-color($theme, neutral, 60);
  $neutralColorLight: mat.get-theme-color($theme, neutral, 80);
  $neutralColorLighter: mat.get-theme-color($theme, neutral, 90);

  $warnColor: mat.get-theme-color($theme, error);

  $hoverColor: rgba(mat.get-theme-color($theme, surface-container), 0.5);
  .ag-theme-alpine {
    --highlight-row-background-color: rgba(0, 0, 0, 0.1);
    .ag-cell-selected {
      border-color: white !important;
      background-color: $neutralColorLight !important;
    }
    --ag-alpine-active-color: #{$neutralColor};
    --ag-checkbox-checked-color: #{$neutralColor};
    --ag-row-hover-color: #{$hoverColor};
    --ag-foreground-color: black;
    --ag-background-color: transparent;
    --ag-header-background-color: transparent;
    --ag-control-panel-background-color: white;
    --ag-selected-row-background-color: #{$neutralColorLighter};
    --ag-range-selection-border-color: #{$neutralColor};
    --ag-modal-overlay-background-color: transparent;
    --ag-input-focus-border-color: black;
    --ag-tooltip-background-color: var(
      --mdc-plain-tooltip-container-color,
      var(--mat-app-inverse-surface)
    );
    .ag-tooltip {
      --ag-foreground-color: var(
        --mdc-plain-tooltip-supporting-text-color,
        var(--mat-app-inverse-on-surface)
      );
    }
    --ag-row-border-color: #{$neutralColorLighter};
    mat-error {
      color: #{$warnColor};
    }
  }
}

@mixin navmenu-mixin($theme) {
  $neutralColor: mat.get-theme-color($theme, neutral, 35);
  $neutralColorLight: mat.get-theme-color($theme, neutral, 87);
  $neutralColorLighter: mat.get-theme-color($theme, neutral, 90);

  $primaryColorDark: mat.get-theme-color($theme, primary, 25);

  $text-color: mat.get-theme-color($theme, on-surface);

  .lightMode {
    salary-navigation-menu {
      .active-nav-group,
      .root-item:focus,
      .nav-menu-list-item:focus,
      .show-all-nodes-button.within-active-group {
        background-color: $neutralColorLighter;
      }
      .root-item .mat-icon {
        color: $primaryColorDark;
      }
      .root-item.is-expanded .mat-icon {
        color: rgba(0, 0, 0, 0.54);
      }
      .active-nav-item {
        background-color: $neutralColor !important;
        * {
          color: white !important;
        }
        .mat-icon {
          color: white;
        }
      }

      .active-nav-group.mat-mdc-list-item:hover:not(.active-nav-item) {
        animation: colorFaderActive 0.15s linear;
        animation-fill-mode: forwards;
      }

      .mat-mdc-list-item:hover:not(.active-nav-group) {
        animation: colorFaderNotActive 0.15s linear;
        animation-fill-mode: forwards;
      }

      .mat-mdc-nav-list .group-divider {
        display: none;
      }

      @keyframes colorFaderActive {
        from {
          background-color: $neutralColorLighter;
        }
        to {
          background-color: $neutralColorLight;
        }
      }

      @keyframes colorFaderNotActive {
        from {
          background-color: white;
        }
        to {
          background-color: $neutralColorLight;
        }
      }

      .mat-mdc-nav-list .mat-mdc-list-item:hover {
        background-color: $neutralColor;
      }

      .mat-mdc-list-item:focus:not(.active-nav-group) {
        background-color: white;
        color: $text-color;
      }
    }
    .navigation-menu-search-input-overlay {
      background-color: white;
    }
  }
}

@mixin common-mixin($theme) {
  $primaryColorLighter: mat.get-theme-color($theme, primary, 80);
  $primaryColorLight: mat.get-theme-color($theme, primary, 50);
  $primaryColor: mat.get-theme-color($theme, primary, 40);
  $primaryColorDark: mat.get-theme-color($theme, primary, 35);
  $neutralColor: mat.get-theme-color($theme, neutral, 60);
  $neutralColorLight: mat.get-theme-color($theme, neutral, 90);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  $warnColor: mat.get-theme-color($theme, error);
  $label-color: rgba(mat.get-theme-color($theme, on-surface), 0.68);
  $divider-color: var(--mat-divider-color);
  $hoverColor: mat.get-theme-color($theme, surface-container);
  $backgroundColor: mat.get-theme-color($theme, surface);
  & {
    --salary-info-color: #6c96eb;
    --salary-warning-color: #d99d3a;
    --salary-error-color: #{$warnColor};
    --salary-correct-color: #52af2e;
    --mat-badge-background-color: #{$neutralColor};
    --mdc-elevated-card-container-color: #{$backgroundColor};
    --mat-expansion-header-description-color: #{$label-color};
    --elevation-color: 0, 0, 0;
  }
  .mat-warn {
    --mdc-fab-small-container-color: #{$warnColor};
    --mat-fab-small-foreground-color: white;
    --mat-icon-color: #{$warnColor};
  }
  .invalid-indication {
    color: $warnColor !important;
  }
  .mat-primary {
    --mdc-icon-button-icon-color: #{$primaryColor};
  }

  .badge-warn {
    --mat-badge-background-color: #{$warnColor};
    --mat-badge-text-color: white;
  }

  .badge-primary {
    --mat-badge-background-color: #{$primaryColor};
    --mat-badge-text-color: white;
  }

  .termine-more-button {
    color: $primaryColor !important;
  }

  .badge-loading {
    background-color: white;
  }

  .html-text-editor-background-color {
    background-color: $backgroundColor;
  }

  .html-edit-button-background-color {
    background-color: $primaryColor !important;
  }

  .html-text-editor-color {
    color: black;
  }

  salary-identification-panel {
    .mat-expansion-panel-content {
      border-color: $primaryColor !important;
    }
    .expand-icon {
      color: white;
    }
    mat-expansion-panel-header {
      background-color: $primaryColor !important;
    }
    mat-expansion-panel-header:hover {
      background-color: $primaryColorLight !important;
    }
  }

  .expandable-header-second-line {
    color: $label-color;
  }

  .button-hovered {
    background-color: $hoverColor !important;
  }

  salary-wochenbericht-status .text-item {
    background-color: $primaryColorLighter;
  }
  salary-wochenbericht-status .separator {
    background-color: $neutralColor;
  }

  .pulse {
    background-color: $primaryColorLighter;
  }
  .pulse-icon {
    color: black;
  }

  .search-panel-header {
    background-color: $backgroundColor;
  }

  .lightMode .mat-mdc-snack-bar-container {
    --mat-mdc-snack-bar-button-color: #{$primaryColorLighter} !important;
  }
  .red-snackbar {
    --mdc-snackbar-supporting-text-color: white !important;
    --mdc-snackbar-container-color: #{$warnColor} !important;
    --mdc-icon-button-icon-color: white !important;
    .progressbar-timer-progress {
      background-color: white;
    }
  }

  .progressbar-timer-progress {
    background-color: var(--mat-snack-bar-button-color);
  }

  .start-line::before {
    background-color: #ebeeef;
  }

  .month-overlay {
    background-color: white;
    color: $primaryColor;
  }

  .month-overlay-data-style {
    background-color: $primaryColor !important;
    color: white !important;
  }

  .top-toolbar {
    a {
      color: white !important;
    }
    img {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(188deg)
        brightness(108%) contrast(101%);
    }
  }

  .linkColorAccent {
    color: $primaryColorDark;
    &.mat-mdc-button {
      --mdc-text-button-label-text-color: #{$primaryColorDark};
    }
    &:hover {
      text-decoration: underline;
    }
    text-decoration: none;
  }

  .colorAccent {
    color: $primaryColor;
  }
  .colorPrimary {
    color: $neutralColor;
  }

  .global-search-panel,
  .lohnkontext-autocomplete-overlay {
    mat-divider {
      border-color: white !important;
    }
  }

  .global-search-panel {
    .content-div {
      color: white;
    }
  }

  .borderColorDivider {
    border-bottom-color: $divider-color !important;
  }

  .disabled-button {
    color: $disabledColor;
  }

  salary-banner {
    --mdc-icon-button-icon-color: white;
    --mat-icon-button-hover-state-layer-opacity: 0.2;
    .banner-container {
      background-color: $primaryColor;
      color: white;
    }
  }

  .mat-button-toggle.mat-mdc-raised-button:not([disabled='true']):not(
      [disabled]
    ) {
    background-color: $primaryColor;
    color: white;
    border-color: white !important;
  }
  .mat-button-toggle.mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-label-text-color: black;
  }

  .calendar-list {
    .date-label {
      color: $label-color;
    }

    .green-white-style {
      background-color: $primaryColor;
      color: white;
    }

    .cell-kw-style {
      color: $neutralColor;
    }

    .even-calendar-row {
      background-color: rgba($neutralColor, 0.2);
    }

    .striped-cell:not(.even-calendar-row)::before {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 3px,
        rgba($neutralColor, 0.4) 3px,
        rgba($neutralColor, 0.4) 6px
      );
    }

    .striped-cell.even-calendar-row::before {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 3px,
        rgba($neutralColor, 0.3) 3px,
        rgba($neutralColor, 0.3) 6px
      );
    }
  }

  .active-application-toolbar-item {
    background-color: white !important;
    color: $primaryColor !important;
  }

  .abwesenheiten-tooltip {
    background: white !important;
  }

  .tooltip-header {
    color: black;
    background: $primaryColorLighter;
  }

  .mat-toolbar:not(.top-toolbar) {
    background-color: inherit;
  }

  .lightMode {
    .mat-mdc-form-field {
      .mdc-text-field--disabled {
        .mdc-floating-label,
        .mdc-notched-outline * {
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
      .mdc-text-field--disabled .mdc-text-field__input {
        color: #0009 !important;
      }
      mat-hint {
        color: color.scale($label-color, $alpha: +30%);
      }
    }

    .mat-calendar-body-selected {
      background-color: $primaryColor;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ),
    .cdk-keyboard-focused
      .mat-calendar-body-active
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ),
    .cdk-program-focused
      .mat-calendar-body-active
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ) {
      background-color: $primaryColorLighter !important;
    }

    .mat-mdc-outlined-button:not([disabled]) {
      border-color: unset;
    }

    // selected item in enum editor was grey because our primary palette is grey, looked like disabled
    // also background color was very shine
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      background: $neutralColorLight !important;
    }
    .mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      color: black !important;
    }
  }

  .mat-mdc-snack-bar-action .mat-mdc-button,
  .mat-simple-snackbar-action .mat-mdc-icon-button {
    color: white !important;
  }

  .tooltip-right::after {
    border-color: transparent $neutralColor transparent transparent;
  }

  .tooltip-left::after {
    border-color: transparent transparent transparent $neutralColor;
  }

  .mat-step-header:has(.mat-step-label-selected) {
    border-radius: var(--mat-stepper-header-hover-state-layer-shape);
    background-color: var(--mat-stepper-header-hover-state-layer-color);
  }

  .mat-tree {
    .mat-tree-node:hover {
      background-color: $hoverColor;
    }
    .sticky-node {
      background-color: $backgroundColor;
    }
  }

  .spaltenauswahl-panel {
    --mat-autocomplete-background-color: #{$backgroundColor};
  }

  mat-optgroup.sticky {
    > span {
      background-color: $primaryColorLighter;
    }
  }

  .warning-tooltip {
    background-color: white;
  }

  .fullscreen-empty-state-overlay {
    background-color: $backgroundColor;
  }

  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #{$primaryColor};
  }

  .top-toolbar .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: white;
  }

  .global-search-container input::placeholder {
    color: white !important;
  }
  .breadcrumb-panel-container {
    background-color: $backgroundColor;
  }
  .selected-breadcrumb-button {
    background-color: $neutralColorLight !important;
  }

  .mat-mdc-list-item-icon.mat-icon-no-color {
    --mdc-list-list-item-hover-leading-icon-color: black;
    --mdc-list-list-item-leading-icon-color: black;
  }

  .validation-warning-badge .mat-badge-content,
  salary-validation-detail .avatar-warning .avatar-initials {
    --mat-badge-background-color: var(--salary-warning-color);
    --mat-badge-text-color: #{$backgroundColor};
    --salary-avatar-background-color: var(--salary-warning-color);
    --salary-avatar-text-color: #{$backgroundColor};
  }

  .validation-info-badge .mat-badge-content,
  salary-validation-detail .avatar-info .avatar-initials {
    --mat-badge-background-color: var(--salary-info-color);
    --mat-badge-text-color: #{$backgroundColor};
    --salary-avatar-background-color: var(--salary-info-color);
    --salary-avatar-text-color: #{$backgroundColor};
  }

  .right-aligned-value {
    color: $label-color;
  }

  .validation-list-item,
  .notification-list-item,
  salary-overview-card {
    --mat-card-subtitle-text-color: #{$label-color};
  }
}

@mixin avatar-mixin($theme) {
  $primaryColor: mat.get-theme-color($theme, primary, 40);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  $warnColor: mat.get-theme-color($theme, error);

  .avatar-initials {
    --salary-avatar-background-color: #{$primaryColor};
    --salary-avatar-text-color: white;
  }
  .avatar.warn {
    --salary-avatar-background-color: #{$warnColor};
  }
  .avatar-initials.avatar-disabled {
    --salary-avatar-background-color: #{$disabledColor};
    --salary-avatar-text-color: black;
  }

  .avatar-initials.avatar-outlined:not(
      .lightMode .toolbar-overflow-menu .avatar-initials.avatar-outlined
    ) {
    --salary-avatar-background-color: transparent;
    --salary-avatar-text-color: white;
  }
}

@mixin empty-state-mixin($theme) {
  $neutralColorLight: mat.get-theme-color($theme, neutral, 87);

  .empty-state-rounded {
    background-color: $neutralColorLight;
  }
  .empty-state-icon {
    color: rgba(0, 0, 0, 0.5);
  }
}

@mixin lohnkontext-mixin($theme) {
  $neutralColorLight: mat.get-theme-color($theme, neutral, 90);
  $primaryColor: mat.get-theme-color($theme, primary, 40);
  $primaryColorLight: mat.get-theme-color($theme, primary, 70);

  .lightMode {
    .top-toolbar {
      --mat-toolbar-container-background-color: #{$primaryColor};
      --mat-toolbar-container-text-color: white;
      --mdc-icon-button-icon-color: white;
      --mat-icon-button-hover-state-layer-opacity: 0.2;
      salary-lohnkontext-container {
        .mat-mdc-icon-button:disabled {
          color: rgba(white, 0.38) !important;
        }
      }
      .mat-datepicker-toggle {
        color: white;
      }
      .date-changed input {
        background-color: $primaryColor;
      }
    }
    .lohnkontext-autocomplete-overlay,
    .global-search-panel {
      background-color: $primaryColor !important;
      color: white !important;
      border-color: white !important;
      .mat-mdc-progress-spinner circle {
        stroke: white;
      }
      .mdc-list-item__primary-text {
        color: white;
      }
      .mat-mdc-button:not([disabled]) {
        color: white;
      }
      .empty-state,
      .empty-state-icon,
      .mat-mdc-option .option-container {
        color: white !important;
      }

      .special-option-item {
        background-color: $primaryColor !important;
        color: white !important;
      }
      .mat-mdc-option:not(.mdc-list-item--disabled):hover {
        background: $primaryColorLight !important;
      }
      .mat-mdc-autocomplete-panel {
        border-color: white !important;
      }
      .mat-mdc-autocomplete-panel:hover {
        border-color: $neutralColorLight !important;
      }
      .filter-chip-list .mat-mdc-chip:not(.mat-mdc-chip-selected) {
        --mdc-chip-label-text-color: white;
      }
      --mdc-icon-button-icon-color: white;
    }

    .lohnkontext-autocomplete-overlay::-webkit-scrollbar-thumb:hover,
    .global-search-panel::-webkit-scrollbar-thumb:hover {
      border-color: $neutralColorLight !important;
    }

    .lohnkontext-autocomplete-overlay,
    .global-search-panel,
    .banner-container {
      $primaryColor: mat.get-theme-color($theme, primary, 40);
      $shimmerAccentBackgroundColor: color.scale($primaryColor, $lightness: 3%);
      $darkerAccentShimmerBackgroundColor: color.scale(
        $shimmerAccentBackgroundColor,
        $lightness: 3%
      );
      .shimmer-shine {
        background-color: $shimmerAccentBackgroundColor;
        background-image: linear-gradient(
          to right,
          $shimmerAccentBackgroundColor 0%,
          $darkerAccentShimmerBackgroundColor 20%,
          $shimmerAccentBackgroundColor 40%,
          $shimmerAccentBackgroundColor 100%
        );
      }
    }

    .lohnkontext-datepicker-overlay {
      background-color: $primaryColor !important;
      color: white !important;

      .mat-calendar-body-cell-content,
      .mat-mdc-icon-button,
      .mat-calendar-period-button {
        color: white !important;
      }

      .mat-calendar-body-selected {
        background-color: white !important;
        color: $primaryColor !important;
      }

      .mat-calendar-body-today:not(.mat-calendar-body-selected):not(
          .mat-calendar-body-comparison-identical
        ) {
        border-color: white;
      }
    }
  }
}

@mixin scrollbar-mixin($theme) {
  $neutralColorLight: mat.get-theme-color($theme, neutral, 80);
  $neutralColorLighter: mat.get-theme-color($theme, neutral, 87);
  .mat-mdc-nav-list,
  .mat-mdc-input-element,
  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel,
  .mat-mdc-dialog-content,
  .ag-body-viewport,
  .ag-center-cols-viewport,
  .ag-virtual-list-viewport,
  .ag-body-vertical-scroll-viewport,
  .ag-body-horizontal-scroll-viewport,
  .ag-popup-child,
  .ag-tool-panel-wrapper,
  .mat-mdc-tab-body-content,
  .mat-mdc-menu-panel,
  .small-scrollbar {
    border-color: rgba(0, 0, 0, 0);
    &:hover {
      border-color: $neutralColorLighter;
    }
    &::-webkit-scrollbar-thumb:hover {
      border-color: $neutralColorLight;
    }
  }
  .ag-root-wrapper:hover {
    .ag-body-horizontal-scroll > .ag-body-horizontal-scroll-viewport,
    .ag-body-vertical-scroll > .ag-body-vertical-scroll-viewport {
      border-color: $neutralColorLighter;
    }
  }
}

@mixin abrechnung-process-button-mixin($theme) {
  $primaryColor: mat.get-theme-color($theme, primary, 40);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  .process-step-button:not(.process-step-button-unknown),
  .process-step-button-chain {
    --process-step-diabled-color: #{$disabledColor};
    background-color: $disabledColor;
    .mat-icon {
      color: white !important;
    }
  }
  .process-step-button-unknown {
    border: 2px solid $disabledColor;
  }
  .process-step-button-ready-to-start:not(.process-step-button-chain):not(
      .process-step-button-disabled
    ),
  .process-step-button-chain-end.process-step-button-ready-to-start-until {
    background-color: $primaryColor !important;
  }
  .process-step-button-disabled {
    --mat-badge-background-color: #{$disabledColor};
  }
  .process-step-button-pending:after {
    border-top-color: $primaryColor !important;
  }
  .chain-line-after,
  .chain-line-before {
    --mat-divider-color: #{$primaryColor};
  }
  .mat-step:has(.process-step-button-chain) .mat-stepper-vertical-line::before {
    --process-step-button-chain-line-color: #{$primaryColor};
  }
}

@mixin shimmer-mixin($theme) {
  $backgroundDialogColor: mat.get-theme-color($theme, surface-container);
  $shimmerBackgroundColor: color.scale($backgroundDialogColor, $lightness: -5%);
  $darkerShimmerBackgroundColor: color.scale(
    $shimmerBackgroundColor,
    $lightness: -5%
  );
  .shimmer-shine {
    background-color: $shimmerBackgroundColor;
    background-image: linear-gradient(
      to right,
      $shimmerBackgroundColor 0%,
      $darkerShimmerBackgroundColor 20%,
      $shimmerBackgroundColor 40%,
      $shimmerBackgroundColor 100%
    );
  }
}
