@use '@angular/material' as mat;
@use 'sass:map';
@use 'sass:color';
@mixin ag-grid-mixin($theme) {
  $primaryColorDark: mat.get-theme-color($theme, primary, 60);
  $neutralColor: mat.get-theme-color($theme, neutral, 60);
  $neutralColorDark: mat.get-theme-color($theme, neutral, 40);
  $neutralColorLight: mat.get-theme-color($theme, neutral, 96);

  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  $backgroundHover: mat.get-theme-color($theme, neutral, 30);
  $backgroundDialogColor: mat.get-theme-color($theme, surface-bright);

  .ag-dnd-ghost {
    background-color: $backgroundDialogColor !important;
  }
  .ag-theme-alpine {
    --highlight-row-background-color: rgba(255, 255, 255, 0.1);
    .ag-cell-selected {
      background-color: $neutralColorDark !important;
      border-color: $neutralColorLight !important;
    }
    --ag-checkbox-checked-color: #{$primaryColorDark} !important;
    --ag-row-hover-color: #{$backgroundHover};
    --ag-alpine-active-color: white;
    --ag-foreground-color: white;
    --ag-secondary-foreground-color: white;
    --ag-checkbox-checked-color: #{$neutralColor};
    --ag-odd-row-background-color: rgba(255, 255, 255, 0.02);
    --ag-header-foreground-color: white;
    --ag-control-panel-background-color: #{$backgroundDialogColor};
    --ag-selected-row-background-color: #{$neutralColorDark};
    --ag-range-selection-border-color: #{$neutralColor};
    --ag-modal-overlay-background-color: transparent;
    --ag-input-focus-border-color: white;
    --ag-toggle-button-switch-background-color: white;
    --ag-subheader-background-color: transparent;
    --ag-row-border-color: #{$neutralColorDark};
    --ag-border-color: white;

    .ag-side-buttons {
      background-color: $backgroundDialogColor;
    }
    .ag-select-list {
      background-color: $backgroundDialogColor;
    }
    .ag-row-group:has([class*='ag-row-group-indent']) {
      background-color: #{$backgroundDialogColor} !important;
    }
    .ag-header-cell-text {
      font-weight: 500;
    }
  }
}

@mixin common-mixin($theme) {
  $primaryColor: mat.get-theme-color($theme, primary, 50);
  $primaryColorDark: mat.get-theme-color($theme, primary, 60);
  $primaryColorLight: mat.get-theme-color($theme, primary, 80);
  $primaryColorLighter: mat.get-theme-color($theme, primary, 95);
  $primaryColorDarkest: mat.get-theme-color($theme, primary, 35);
  $primaryColorDarkestContrast: mat.get-theme-color($theme, on-primary);
  $neutralColor: mat.get-theme-color($theme, neutral, 60);
  $neutralLightColor: mat.get-theme-color($theme, neutral, 87);
  $neutralColorDark: mat.get-theme-color($theme, neutral, 40);
  $neutralColorDarker: mat.get-theme-color($theme, neutral, 35);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  $textColor: mat.get-theme-color($theme, on-surface);
  $backgroundColor: mat.get-theme-color($theme, surface);
  $appBarBackgroundColor: mat.get-theme-color($theme, surface-bright);
  $backgroundDialogColor: mat.get-theme-color($theme, surface-container);
  $label-color: mat.get-theme-color($theme, neutral, 70);
  $divider-color: var(--mat-divider-color);
  $backgroundCardColor: mat.get-theme-color($theme, surface-container-high);
  $hoverColor: mat.get-theme-color($theme, surface-container);
  $warnColor: mat.get-theme-color($theme, error);
  $onWarnTextColor: mat.get-theme-color($theme, on-error);
  & {
    --mat-expansion-container-background-color: #{$backgroundCardColor};
    --mdc-elevated-card-container-color: #{$backgroundCardColor};
    --mat-menu-container-color: #{$backgroundCardColor};
    --mdc-dialog-container-color: #{$backgroundCardColor};
    --mat-badge-background-color: #{$neutralColor};
    --mat-badge-text-color: #{$primaryColorDarkestContrast};
    --elevation-color: 255, 255, 255;
  }
  .mat-warn {
    --mdc-fab-small-container-color: #{$warnColor};
    --mat-fab-small-foreground-color: #{$onWarnTextColor};
    --mat-icon-color: #{$warnColor};
  }
  .badge-warn {
    --mat-badge-background-color: #{$warnColor};
    --mat-badge-text-color: #{$onWarnTextColor};
  }
  .badge-primary {
    --mat-badge-background-color: #{$primaryColor};
    --mat-badge-text-color: white;
  }

  .termine-more-button {
    color: $primaryColor !important;
  }

  .invalid-indication {
    color: $warnColor !important;
  }

  .red-snackbar {
    --mdc-snackbar-supporting-text-color: #{$onWarnTextColor} !important;
    --mdc-snackbar-container-color: #{$warnColor} !important;
    --mdc-icon-button-icon-color: #{$onWarnTextColor} !important;
    .mat-mdc-icon-button {
      color: #{$onWarnTextColor} !important;
    }
    .progressbar-timer-progress {
      background-color: #{$onWarnTextColor};
    }
  }

  .progressbar-timer-progress {
    background-color: var(--mat-snack-bar-button-color);
  }

  .cdk-overlay-container {
    --mat-stepper-container-color: tranparent;
  }

  .html-text-editor-background-color {
    background-color: $backgroundCardColor;
  }

  .badge-loading {
    background-color: white;
  }

  .html-edit-button-background-color {
    background-color: $backgroundColor !important;
  }

  .html-text-editor-color {
    color: white;
  }

  salary-identification-panel {
    .mat-expansion-panel-content {
      border-color: $primaryColor !important;
    }
    mat-expansion-panel-header {
      background-color: $primaryColor !important;
    }

    mat-expansion-panel-header:hover {
      background-color: $primaryColorDark !important;
    }
  }

  .expandable-header-second-line {
    color: $label-color;
  }

  input:not(.mat-mdc-form-field-input-control):not(
      .top-toolbar input
    )::placeholder {
    font-weight: 500;
  }

  .button-hovered {
    background-color: $hoverColor !important;
  }

  salary-wochenbericht-status .text-item {
    background-color: $primaryColor;
  }
  salary-wochenbericht-status .separator {
    background-color: $neutralColor;
  }

  .mat-mdc-form-field {
    .mdc-text-field--disabled {
      .mdc-floating-label {
        color: rgba(255, 255, 255, 0.6) !important;
      }
      .mdc-text-field__input {
        color: #ffffff80 !important;
      }
    }
    mat-hint {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .pulse {
    background-color: $primaryColorLight;
  }

  .pulse-icon {
    color: white;
  }

  .current-page-info {
    color: white;
  }

  .mat-mdc-input-element {
    caret-color: white;
    color: $textColor;
  }

  .mat-mdc-card {
    color: $textColor;
  }

  .start-line::before {
    background-color: $neutralColor;
  }

  salary-banner {
    .banner-container {
      background-color: transparent;
      color: $textColor;
      border-color: white;
    }
  }

  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #{$primaryColor};
  }

  .mat-mdc-button.mat-accent,
  .mat-button,
  .mat-mdc-icon-button {
    color: $textColor;
  }

  .colorPrimary {
    color: white;
  }

  .disabled-button {
    color: $disabledColor;
  }

  .colorAccent {
    color: white;
  }

  .top-toolbar {
    img {
      filter: invert(68%) sepia(8%) saturate(3407%) hue-rotate(58deg)
        brightness(93%) contrast(90%);
    }
    salary-lohnkontext-container {
      .mat-mdc-button-base {
        color: $textColor !important;
      }
      .date-changed input {
        background-color: $appBarBackgroundColor;
      }
    }
  }
  .linkColorAccent {
    color: $primaryColorLighter;
    &.mat-mdc-button {
      --mdc-text-button-label-text-color: #{$primaryColorLighter};
    }
  }
  .mat-mdc-outlined-button:not([disabled='true']):not([disabled]):not(
      .mat-button-toggle-disabled
    ) {
    border-color: white;
    color: white;
  }
  .mat-mdc-outlined-button {
    border-color: $disabledColor;
    color: $disabledColor;
  }

  .top-toolbar.mat-toolbar {
    background-color: $appBarBackgroundColor;
  }

  .sideNavContentHeaderToolbar {
    background-color: $backgroundColor;
  }

  mat-sidenav {
    background-color: $backgroundColor;
  }

  .mat-icon.mat-accent {
    color: $textColor;
  }

  .search-input {
    color: $textColor;
    background-color: transparent;
  }

  .mat-mdc-snack-bar-action .mat-mdc-button,
  .mat-simple-snackbar-action .mat-mdc-icon-button {
    color: $backgroundDialogColor !important;
  }
  .mat-button-toggle.mat-mdc-raised-button:not([disabled='true']):not(
      [disabled]
    ) {
    background-color: $backgroundDialogColor;
  }
  .mat-button-toggle.mat-mdc-outlined-button {
    background-color: transparent;
  }

  .tooltip-right::after {
    border-color: transparent white transparent transparent;
  }

  .tooltip-left::after {
    border-color: transparent transparent transparent white;
  }

  .mat-tree {
    .mat-tree-node:hover {
      background-color: $hoverColor;
    }
    .sticky-node {
      background-color: $appBarBackgroundColor;
    }
  }
  mat-optgroup.sticky {
    > span {
      background-color: $primaryColorDarkest;
    }
  }

  .spaltenauswahl-panel {
    --mat-autocomplete-background-color: #{$backgroundDialogColor};
  }

  .calendar-list {
    .date-label {
      color: $label-color;
    }

    .green-white-style {
      background-color: $primaryColorDarkest;
      color: $textColor;
    }

    .cell-kw-style {
      color: $neutralLightColor;
    }

    .even-calendar-row {
      background-color: rgba($neutralColor, 0.4);
    }

    .striped-cell:not(.even-calendar-row)::before {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 3px,
        $neutralColorDarker 3px,
        $neutralColorDarker 6px
      );
    }

    .striped-cell.even-calendar-row::before {
      background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 3px,
        $neutralColorDark 3px,
        $neutralColorDark 6px
      );
    }
  }

  .abwesenheiten-tooltip {
    color: $textColor;
    background: $backgroundColor !important;
  }

  .tooltip-header {
    color: $textColor;
    background: $primaryColorDarkest;
  }

  .active-application-toolbar-item {
    color: $backgroundDialogColor !important;
  }

  .borderColorDivider {
    border-bottom-color: $divider-color !important;
  }

  .month-overlay {
    background-color: $primaryColorDarkest;
    color: $textColor;
  }

  .month-overlay-data-style {
    background-color: $backgroundDialogColor !important;
  }

  .warning-tooltip {
    background-color: $backgroundDialogColor !important;
    color: white;
  }

  .warning-icon {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .fullscreen-empty-state-overlay {
    background-color: $backgroundCardColor;
  }

  .breadcrumb-panel-container {
    background-color: $backgroundCardColor;
  }
  .selected-breadcrumb-button {
    background-color: $backgroundCardColor !important;
  }

  // selected item in enum editor was grey because our primary palette is grey, looked like disabled
  .mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text,
  .mat-mdc-list-item-icon.mat-icon-no-color {
    color: white !important;
  }

  .validation-warning-badge .mat-badge-content,
  salary-validation-detail .avatar-warning .avatar-initials {
    --mat-badge-text-color: #{$backgroundColor};
    --salary-avatar-text-color: #{$backgroundColor};
  }

  .validation-info-badge .mat-badge-content,
  salary-validation-detail .avatar-info .avatar-initials {
    --mat-badge-text-color: #{$backgroundColor};
    --salary-avatar-text-color: #{$backgroundColor};
  }

  .right-aligned-value {
    color: $label-color;
  }

  .validation-list-item,
  .notification-list-item,
  salary-overview-card {
    --mat-card-subtitle-text-color: #{$label-color};
  }
  .notification-list-item,
  .validation-list-item,
  salary-tip-detail {
    --mat-app-elevation-shadow-level-2: none;
    // copied from --mat-app-elevation-shadow-level-8 but with 255,255,255 instead of 0,0,0
    --mat-app-elevation-shadow-level-8:
      0px 5px 5px -3px rgba(var(--elevation-color), 0.2),
      0px 8px 10px 1px rgba(var(--elevation-color), 0.14),
      0px 3px 14px 2px rgba(var(--elevation-color), 0.12);
  }

  .mat-expansion-panel,
  .mat-mdc-card,
  .mat-mdc-dialog-container {
    .mat-expansion-panel,
    .mat-mdc-card {
      --mat-expansion-container-background-color: #{$appBarBackgroundColor};
      --mdc-elevated-card-container-color: #{$appBarBackgroundColor};
    }
  }
}

@mixin avatar-mixin($theme) {
  $foreground: map.get($theme, foreground);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  $warnColor: mat.get-theme-color($theme, error);
  $onWarnTextColor: mat.get-theme-color($theme, on-error);

  .avatar.warn {
    --salary-avatar-background-color: #{$warnColor};
    --salary-avatar-text-color: #{$onWarnTextColor};
  }

  .avatar-initials.avatar-disabled {
    --salary-avatar-background-color: #{$disabledColor};
    --salary-avatar-text-color: black;
  }
}

@mixin empty-state-mixin($theme) {
  $primaryColorDark: mat.get-theme-color($theme, primary, 50);
  $primaryColorDarkContrast: mat.get-theme-color($theme, on-primary);
  $textColor: mat.get-theme-color($theme, on-surface);

  .empty-state-icon,
  .empty-state-container {
    color: $textColor;
  }

  .empty-state-rounded {
    background-color: $primaryColorDark;
    .empty-state-container,
    .empty-state-icon {
      color: $primaryColorDarkContrast;
    }
  }
}

@mixin lohnkontext-mixin($theme) {
  $backgroundDialogColor: mat.get-theme-color($theme, surface-bright);
  .mat-datepicker-content:has(.lohnkontext-datepicker-overlay),
  .global-search-panel,
  .lohnkontext-autocomplete-overlay {
    --mat-autocomplete-background-color: #{$backgroundDialogColor};
    --mat-datepicker-calendar-container-background-color: #{$backgroundDialogColor};
  }
}

@mixin navmenu-mixin($theme) {
  $neutralColorDark: mat.get-theme-color($theme, neutral, 20);
  $primaryColorDark: mat.get-theme-color($theme, primary, 60);
  $backgroundColor: mat.get-theme-color($theme, surface);
  $backgroundDialogColor: mat.get-theme-color($theme, surface-bright);

  salary-navigation-menu {
    .mat-mdc-nav-list .group-divider {
      border-top-color: white;
    }
    .active-nav-item {
      background-color: $primaryColorDark !important;
    }

    .mat-mdc-nav-list .mat-icon {
      color: white;
    }

    .root-item.is-expanded .mat-icon {
      color: rgba(255, 255, 255, 0.7);
    }

    .mat-mdc-list-item:hover:not(.active-nav-item),
    .mat-list-item:hover:not(.active-nav-item) .mat-icon {
      animation: colorFaderDarkNotActive 0.15s linear;
      animation-fill-mode: forwards;
    }

    @keyframes colorFaderDarkNotActive {
      to {
        background-color: $neutralColorDark;
      }
    }

    .mat-mdc-list-item:focus:not(:hover):not(.active-nav-item) {
      background-color: $backgroundDialogColor;
      color: white;
    }
  }
  .navigation-menu-search-input-overlay {
    background-color: $backgroundColor;
  }
}

@mixin abrechnung-process-button-mixin($theme) {
  $primaryColorLight: mat.get-theme-color($theme, primary, 50);
  $disabledColor: rgba(mat.get-theme-color($theme, on-surface), 0.38);
  .process-step-button:not(.process-step-button-unknown),
  .process-step-button-chain {
    --process-step-diabled-color: #{$disabledColor};
    background-color: $disabledColor;
  }
  .process-step-button-unknown {
    border: 2px solid $disabledColor;
  }
  .process-step-button-ready-to-start:not(.process-step-button-chain):not(
      .process-step-button-disabled
    ),
  .process-step-button-chain-end.process-step-button-ready-to-start-until {
    background-color: $primaryColorLight !important;
  }
  .process-step-button-pending:after {
    border-top-color: $primaryColorLight !important;
  }
  .chain-line-after,
  .chain-line-before {
    --mat-divider-color: #{$primaryColorLight};
  }
  .mat-step:has(.process-step-button-chain) .mat-stepper-vertical-line::before {
    --process-step-button-chain-line-color: #{$primaryColorLight};
  }
}

@mixin shimmer-mixin($theme) {
  $backgroundDialogColor: mat.get-theme-color($theme, surface-container);
  $shimmerBackgroundColor: color.scale($backgroundDialogColor, $lightness: 2%);
  $lighterShimmerBackgroundColor: color.scale(
    $shimmerBackgroundColor,
    $lightness: 5%
  );
  .shimmer-shine {
    background: $shimmerBackgroundColor;
    background-image: linear-gradient(
      to right,
      $shimmerBackgroundColor 0%,
      $lighterShimmerBackgroundColor 20%,
      $shimmerBackgroundColor 40%,
      $shimmerBackgroundColor 100%
    );
  }
}
