/* Add AG-Grid Theme with Base+Material to main style*/
@use 'sass:math';
@use 'sass:color';
@use 'ag-grid-community/styles' as ag;
@use './theming/green-generated.scss';
@include ag.grid-styles(
  (
    theme: alpine,
  )
);

@media print {
  salary-root {
    display: none;
  }
  .cdk-overlay-container {
    display: contents;
  }
  mat-checkbox svg {
    filter: invert(100%);
  }
  .mat-mdc-select-arrow-wrapper {
    visibility: hidden;
  }
  .mat-datepicker-toggle {
    visibility: hidden;
  }
  .link-icon {
    visibility: hidden;
  }
  .ag-theme-alpine .ag-row {
    border-color: lightgray !important;
  }

  .start-line::before {
    background-color: lightgray !important;
  }

  * {
    color: black !important;
  }
}

.mat-drawer-side {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-divider-color: var(--mat-divider-color);
}

.mat-sidenav {
  --mat-sidenav-container-width: auto;
}

mat-optgroup.sticky {
  > span {
    position: sticky;
    z-index: 1;
    top: 0;
  }
}

.multiline-tooltip {
  white-space: pre-line !important;
}

.mat-mdc-optgroup-label {
  font-size: 16px;
}

.ag-theme-alpine {
  --ag-font-size: 13px;
  --ag-input-focus-box-shadow: unset;
  --ag-font-family: Roboto, sans-serif;
  --ag-row-height: 48px;
  --ag-cell-horizontal-padding: 10px;

  .ag-body-horizontal-scroll-viewport {
    overflow-y: hidden;
  }
  .ag-body-vertical-scroll-viewport {
    overflow-x: hidden;
  }
  .ag-menu {
    border: none;
  }
  .ag-row-group.ag-row-group-indent-0 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ag-details-row {
    padding: 5px 0 5px 16px;
  }

  .ag-header {
    font-weight: 500;
  }
  .ag-floating-top {
    overflow-y: hidden !important;
  }
  .ag-root-wrapper {
    border: 0px;
  }
  .ag-row .ag-cell:not(.ag-cell-inline-editing) ng-component {
    flex: 1;
  }
  .ag-cell:not(:has(.ag-group-value)) {
    line-height: normal;
  }

  .cell-button-style {
    border: none !important;
    text-align: center;
    padding-left: 0 !important;
  }
  .checkbox-header .ag-header-cell-label {
    place-content: center;
  }
  .ag-right-aligned-header {
    text-align: end;
  }

  .ag-filter-toolpanel-instance-filter {
    border-left: 0 !important;
  }

  // start: change style for checkbox selection: enlarge checkbox selection interaction area
  // .ag-cell:has(.ag-selection-checkbox) {
  //   padding-left: 0; //normally 9px
  //   padding-right: 0;
  // }
  // .ag-selection-checkbox {
  //   margin-right: 0; //normally 12px
  //   .ag-checkbox {
  //     height: 100%;
  //   }
  //   .ag-checkbox-input-wrapper {
  //     height: 100%;
  //     width: calc(var(--ag-icon-size) + 12px);
  //     background-color: transparent;
  //     &::before,
  //     &::after {
  //       left: 9px;
  //       top: calc(
  //         calc(var(--ag-row-height) / 2) - calc(var(--ag-icon-size) / 2)
  //       );
  //     }
  //   }
  // }
  // end: change style for checkbox selection:
}

.mdc-button.mat-mdc-button > .mat-icon {
  font-size: 24px;
  height: unset;
  width: unset;
}
.mat-mdc-chip-action {
  padding-left: 8px !important;
  .mat-mdc-chip-graphic {
    display: none;
  }
}

.mdc-evolution-chip__cell button {
  width: 100%;
  .mat-mdc-chip-action-label {
    width: 100%;
  }
}

.small-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
  padding: 0 !important;
  .mat-icon {
    height: 20px !important;
    width: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }
  .mat-mdc-button-touch-target {
    width: 30px !important;
    height: 30px !important;
  }
}

.very-small-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
  padding: 0 !important;
  .mat-icon {
    height: 14px !important;
    width: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .mat-mdc-button-touch-target {
    width: 25px !important;
    height: 25px !important;
  }
}

.json-dialog .dialog-content {
  white-space: break-spaces !important;
  font-size: 14px !important;
}
.json-dialog .mat-mdc-dialog-title {
  font-size: 18px !important;
}

// Specific component overrides, pieces that are not in line with the general theming

.mat-mdc-dialog-actions {
  --mat-dialog-actions-padding: 8px 24px;
  justify-content: flex-end !important;
}

mat-error {
  line-height: normal;
  display: flex !important;
}

.mat-mdc-autocomplete-panel,
.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-toolbar.mat-toolbar-single-row:not(.top-toolbar.mat-toolbar) {
  padding: 0;
  height: 48px;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-height: 3px !important;
  --mdc-linear-progress-track-height: 3px !important;
}

body {
  margin: 0;
  padding: 0;
}

:root {
  --mat-dialog-container-max-width: 95vw;
}

html,
body {
  font-family: Roboto, sans-serif;
  height: 100%;
  overflow: hidden;
}

.datatable-container {
  width: 99.7%;
}

.mat-mdc-outlined-button:not([class*='mat-elevation-z']),
.mat-mdc-unelevated-button:not([class*='mat-elevation-z']) {
  min-width: min-content;
}

.mat-mdc-menu-content .mat-mdc-outlined-button:not([class*='mat-elevation-z']) {
  margin-right: 15px;
}

.mat-badge-content {
  letter-spacing: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-button-toggle {
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-weight: 500;
}

.mdc-text-field:has(mat-checkbox) {
  padding-left: 6px; //checbkox should have same padding as input-placeholder
}

.mat-mdc-checkbox {
  max-width: 100%;
  > .mdc-form-field {
    max-width: 100%;
    > label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mat-mdc-form-field-infix {
  z-index: 1;
  &:has(mat-checkbox) {
    --mat-form-field-container-vertical-padding: 8px;
  }
}

.mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  opacity: 0.28 !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-label-text-size: 16px;
}

.mat-mdc-form-field {
  margin-bottom: 4px;
  --mat-form-field-subscript-text-line-height: normal;
  --mat-checkbox-label-text-size: 16px;
  .mat-mdc-form-field-subscript-wrapper {
    min-height: 22px;
    white-space: pre-line;
    padding-bottom: 8px;
  }
  .mat-mdc-form-field-bottom-align::before {
    height: auto;
  }
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

input:not(.mat-mdc-form-field-input-control) {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: currentColor;
  font-size: inherit;
}

input[disabled] {
  pointer-events: none !important;
}

input {
  font-family: Roboto, sans-serif;
  text-overflow: ellipsis;
}

.mdc-floating-label {
  text-overflow: ellipsis !important;
}

.mat-expansion-panel-header-title {
  letter-spacing: 0.5px;
}

.mat-expansion-panel-content {
  letter-spacing: normal;
}

.mat-expansion-panel-body {
  margin-top: 10px;
}

.mat-expansion-panel-body:has(> salary-form-field:last-child):not(
    :has(ag-grid-angular, salary-label)
  ) {
  padding-bottom: 0 !important;
}

mat-card-content:has(> salary-form-field:last-child):not(
    :has(ag-grid-angular, salary-label)
  ) {
  padding-bottom: 0 !important;
}

.salaryLongTooltip {
  max-width: unset !important;
}

.ag-group-value:has(salary-group-row-cell) {
  width: 100%;
}

.ag-tooltip {
  border-width: 0 !important;
  max-width: 250px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ag-header-cell-text {
  word-break: normal;
}

.ag-dnd-ghost {
  border: 0 !important;
}

.fullscreen-backdrop {
  background-color: white;
}

.mat-mdc-tooltip {
  font-size: 14px;
  padding: 8px 11px !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 3px;
}

.mat-mdc-nav-list,
.mat-mdc-input-element,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel,
.mat-mdc-dialog-content,
.ag-body-viewport,
.ag-center-cols-viewport,
.ag-virtual-list-viewport,
.ag-body-vertical-scroll-viewport,
.ag-body-horizontal-scroll-viewport,
.ag-tool-panel-wrapper,
.ag-popup-child,
.mat-mdc-tab-body-content,
.mat-mdc-menu-panel,
.small-scrollbar {
  transition: border-color 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: auto;
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-right-style: inset;
    border-radius: 8px;
    border-color: inherit;
    border-right-width: calc(100vw + 100vh);
    min-height: 50px;
  }
}

.mdc-dialog__content {
  font-size: 16px !important;
}

.search-animation {
  animation: searchFader 120ms cubic-bezier(0, 0, 0.2, 1);
}

@keyframes searchFader {
  from {
    opacity: 0;
    transform: scaleY(0.8);
    transform-origin: top left;
  }
  to {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top left;
  }
}

:root .link-icon {
  padding-left: 2px;
  display: none;
  cursor: pointer;
  outline: none !important;
  font-size: 13px;
  width: 14px;
  height: 13px;
  vertical-align: -2px;
  min-width: 14px;
}

.loading-button-spinner {
  .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}

.highlight-term {
  font-weight: 600;
  text-decoration: underline;
}

.mat-mdc-card-title {
  font-weight: 300;
}

// BEGIN increased elevation shadows for cards and panels
.mat-expansion-panel:not([class*='mat-elevation-z']),
.mat-mdc-card:not([class*='mat-elevation-z']) {
  // copied from mat-elevation-z3 style
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  margin-right: 2px;
  margin-left: 2px;
}

.mat-expansion-panel:not([class*='mat-elevation-z']):not(
    mat-accordion > mat-expansion-panel
  ),
.mat-mdc-card:not([class*='mat-elevation-z']) {
  margin-top: 9px;
  margin-bottom: 11px;
}

// check if still necessary with material v19 (https://github.com/angular/components/pull/29673)
.mdc-notched-outline:has(
    ~ .mat-mdc-form-field-infix salary-datepicker,
    ~ .mat-mdc-form-field-infix salary-select,
    ~ .mat-mdc-form-field-infix salary-crud-search,
    ~ .mat-mdc-form-field-infix salary-search-input
  )
  .mdc-notched-outline__notch:not(:has(.mdc-floating-label--float-above)) {
  max-width: calc(100% - 55px) !important;
}

.mat-accordion > .mat-expansion-panel:last-child {
  margin-bottom: 11px !important;
}
.mat-accordion > .mat-expansion-panel:first-child {
  margin-top: 9px !important;
}
//END

//BEGIN full height ag grid styling
salary-root {
  .full-height-container,
  form {
    height: 100%;
  }
  form:not(:has(ag-grid-angular, mat-tree)) {
    & form {
      height: initial;
    }
  }
  .mat-mdc-tab-group:not(.mat-mdc-tab-group-dynamic-height) {
    height: 100%;
    .mat-mdc-tab-body-wrapper,
    .mat-mdc-tab-body-content {
      box-sizing: border-box;
      height: 100%;
    }
    .tab-body-content-container {
      height: calc(100% - 20px);
    }
  }

  .mat-mdc-card.fill-height {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: calc(100% - 10px);
    .mat-mdc-card-content {
      flex: 1;
      height: 100%;
    }
  }
}
//END

h2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 20px !important;
}

.mat-step-label {
  font-weight: 400;
}

.mat-step-label-selected {
  font-weight: 500;
}

.help-panel-html-content,
.help-panel-html-content * {
  overflow-x: hidden;
  text-overflow: ellipsis;
  list-style-position: inside;
}

.mat-badge-content:has(.badge-loading) {
  display: flex;
}
.badge-loading {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.break-all-message salary-notification-snack-bar .additional-message {
  word-break: break-all;
}

mat-option span:has(> salary-shimmer) {
  width: 100%;
}

.mat-mdc-option salary-empty-state .mat-icon {
  margin-right: 0;
}

.more-options-icon {
  font-weight: 600;
}

.symbol-badge {
  --mat-badge-text-font: 'Material Symbols Outlined', sans-serif;
  --mat-badge-container-padding: 0;
}

.cdk-global-overlay-wrapper:has(
    salary-loading-page,
    salary-not-ready-page,
    salary-no-tasks-page
  ) {
  z-index: 1001; // one above the default of 1000 of all other overlays
}
